<template>
  <div>

  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      appId: "",
      nonceStr: "",
      packageVal: "",
      signType: "",
      timeStamp: "",
      paySign: "",
    };
  },
  created() {

  },
  mounted() {
    this.parseParams()
    this.payWxLoad()
  },
  methods: {
    parseParams() {
      let decodedParams = decodeURIComponent(this.$route.fullPath.toString());
      console.log(decodedParams)
      // 删除文本中的第一个?之前的字符串
      //       const cleanedText = decodedParams.replace('/', '');
      let i = decodedParams.indexOf('?');
      console.log(i)
      let a = decodedParams.substring(0, i + 1)
      decodedParams = decodedParams.replace(a, "")
      // 按照 '&' 分割文本
      const parts = decodedParams.split('&');
      const queryParamsObj = {};
      for (let i = 0; i < parts.length; i++) {
        const part = parts[i];
        const indexOfAmpersand = part.indexOf('='); // 找到第一个 '&' 的位置
        const key = part.substring(0, indexOfAmpersand); // 截取键
        // 截取值
        queryParamsObj[key] = part.substring(indexOfAmpersand + 1);
      }
      console.log(queryParamsObj);
      this.appId = queryParamsObj.appId
      this.nonceStr = queryParamsObj.nonceStr
      this.packageVal = queryParamsObj.packageVal
      this.signType = queryParamsObj.signType
      this.timeStamp = queryParamsObj.timeStamp
      this.paySign = queryParamsObj.paySign
    },
    payWxLoad() {
      this.$message.success("正在调起微信支付");
      console.log("调起微信支付")
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
          document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
        }
      } else {
        this.onBridgeReady();
      }
    },


    onBridgeReady() {
      WeixinJSBridge.invoke('getBrandWCPayRequest', {
        "appId": this.appId,     //公众号ID，由商户传入
        "timeStamp": this.timeStamp,     //时间戳，自1970年以来的秒数
        "nonceStr": this.nonceStr,      //随机串
        "package": this.packageVal,
        "signType": this.signType,     //微信签名方式：
        "paySign": this.paySign //微信签名
      },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          }
        });
    }
  },
}
</script>
<style scoped></style>